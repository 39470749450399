//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache } from "../decorators/cache.decorator";
import { jSith } from "../util/jquery-replacement";

export class LynxService {
    @Cache()
    public static getUserInstances() {
        return DataAccess.get("/lynx/user/instances.json").then(
            (resp) => {
                return (resp && resp.inst) || [];
            },
            (err) => {
                return [];
            },
        );
    }

    @Cache()
    public static getInstances() {
        return DataAccess.get("/lynx/all/instances.json").then(
            (resp) => {
                return (resp && resp.list.item) || [];
            },
            (err) => {
                return [];
            },
        );
    }

    @Cache()
    public static getExpSecEnabled() {
        return DataAccess.get("/lynx/exp_sec_enabled.json").then(
            (resp) => {
                return S25Util.toBool(resp && resp.expSecEnabled && resp.expSecEnabled.val);
            },
            (err) => {
                return false;
            },
        );
    }

    /**
     *
     * @param method eg, post
     * @param pathAndParams eg, /imex.json?cmd=pause
     * @param payload eg, { uid: [ 'LYNX-123' ] }
     * @description Call every lynx instance the user can access and attempt the method w/ params and payload. Return the first success; else return all errors.
     */
    public static callLynx(method: string, pathAndParams: string, payload?: any) {
        const host = window.location.hostname;
        const protocol = window.location.protocol;
        //To manage lynx authentication for local development call current location a proxy like this is needed
        //	Header edit Set-Cookie "^(WSSESSIONID=[^;]*)(|; Domain=[^;]*)(|; path=[^;]*)(/)"  "$1$2$3"
        // 	Header edit Set-Cookie "^(LYNX-WS-SESSIONID=[^;]*)(|; Domain=[^;]*)(|; path=[^;]*)(/)"  "$1$2$3"
        const domain = host.includes("unival.com") || host === "localhost" ? host : "lynx.collegenet.com";

        let promises: Promise<any>[] = [];
        return LynxService.getUserInstances().then((instances) => {
            jSith.forEachObj(instances, (inst) => {
                const domainForInstance = inst.toLowerCase().startsWith("qa")
                    ? domain.replace("lynx.collegenet.com", "lynx-test.collegenet.com")
                    : domain;
                const url = `${protocol}//${domainForInstance}/lynx-ws/sis/data/${inst}/run${pathAndParams}`;
                switch (method) {
                    case "post":
                        promises.push(DataAccess.post(url, payload));
                        break;
                    case "get":
                        promises.push(DataAccess.get(url));
                        break;
                }
            });

            return S25Util.any(promises);
        });
    }

    public static setState(alienUid: string, paused: boolean) {
        return LynxService.callLynx("post", "/imex.json?cmd=" + (paused ? "pause" : "unpause"), { uid: [alienUid] });
    }

    public static getSingleState(alienUid: string) {
        return LynxService.callLynx("get", "/imex.json?cmd=state&uids=" + alienUid).then((data: any) => {
            return S25Util.valueFind(data, "isPaused", 1);
        });
    }
}
