import { DataAccess } from "../dataaccess/data.access";
import { ContactService } from "./contact.service";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { UserprefService } from "./userpref.service";

export interface Masquerade {
    id?: number;
    sourceFirstName?: string;
    sourceLastName?: string;
    targetFirstName?: string;
    targetLastName?: string;
    userName?: string;
    groupName?: string;
    targetId?: number | null;
    sourceId?: number | null;
    expirationDt?: string;
    itemId?: number;
    itemName?: string;
    masqueradeId?: number;
}
//@author: travis
export class MasqueradeService {
    public static masquerade(targetContactId: number) {
        return ContactService.getContactUsername(targetContactId).then(function (username) {
            const url = DataAccess.injectCaller(
                "/change_session_user.json?newUser=" + encodeURIComponent(username),
                "MasqueradeService.masquerade",
            );
            console.log("url", url);
            return DataAccess.post(url);
        });
    }

    @Timeout
    public static async getMasqueradeCurrentUser() {
        const isLoggedIn = await UserprefService.getLoggedIn();
        if (isLoggedIn) {
            const contId = await UserprefService.getContactId();
            return MasqueradeService.getMasqueradeUsers(contId);
        } else {
            return [];
        }
    }
    @Timeout
    @Cache({ immutable: true, targetName: "MasqueradeService" })
    public static getMasqueradeUsers(contactId?: number) {
        let url = "/masquerade/manage.json";
        if (contactId) url += "?contact_id=" + contactId;
        return DataAccess.get(DataAccess.injectCaller(url, "MasqueradeService.getMasqueradeList")).then(
            function (data) {
                return data?.root || [];
            },
        );
    }

    @Invalidate({ serviceName: "MasqueradeService", methodName: "getMasqueradeUsers" })
    public static deleteMasqueradeUser(id: number) {
        return DataAccess.delete(
            DataAccess.injectCaller("/masquerade/manage.json?itemId=" + id, "MasqueradeService.deleteMasqueradeUser"),
        ).then(function (data) {
            return data;
        });
    }

    @Invalidate({ serviceName: "MasqueradeService", methodName: "getMasqueradeUsers" })
    public static putMasqueradeUser(root: any, mode?: string) {
        let url = "/masquerade/manage.json";
        if (mode) url += "?mode=" + mode;

        return DataAccess.put(DataAccess.injectCaller(url, "MasqueradeService.putMasqueradeUser"), {
            root,
        });
    }
}
